<template>
    <div :class="`masonry-grid w-full relative z-50 min-h-screen ${theme}`" id="portfolio">
        <div class="">
            <client-only>
                <div id="masonry-container" v-bind:class="{'md:max-w-[80%]':!noPadding}" class="mx-auto pt-16 hidden md:block masonry-container">
                    <h2 v-if="showTitle" class="text-center lg:text-5xl text-3xl brother mb-12">{{ $t('pages.newDesign.portfolio') }}</h2>
                    <masonry-wall
                            :items="portfolios"
                            min-columns="2"
                            max-columns="4"
                            :column-width="350" :gap="16">
                        <template #default="{ item, index }">
                            <img
                                    :key="index"
                                    @click="() => showImg(index)"
                                    class="size-full object-cover rounded-xl cursor-pointer"
                                    :src="'https://rollin-wiki.s3.ca-central-1.amazonaws.com/' + item.image_url"
                                    :style="`height: ${item.height}`"
                                    :title="item.title"
                                    :alt="item.title"/>
                        </template>
                    </masonry-wall>

                </div>
                <div class="block md:hidden px-4 py-12">
                    <h2 v-if="showTitle" class="text-center text-3xl brother mb-12">{{ $t('pages.newDesign.portfolio') }}</h2>
                    <div class="flex flex-col gap-4">
                        <div v-for="(portfolio, index) in portfolios" :key="index" class="relative">
                            <img
                                    @click="() => showImg(index)"
                                    class="size-full object-cover rounded-xl cursor-pointer"
                                    :src="'https://rollin-wiki.s3.ca-central-1.amazonaws.com/' + portfolio.image_url"
                                    :style="{ height: '300px' }"
                                    :title="portfolio.title"
                                    :alt="portfolio.title"/>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center w-full mt-12 pb-12" v-bind:class="{'opacity-10 pointer-events-none': isLoading}">
                    <div v-if="isMore && !link">
                        <glow-button :click="loadMore">
                            {{ $t('pages.caseStudies.see_more') }} +
                        </glow-button>
                    </div>
                    <div v-if="link">
                        <glow-button :to="localePath('/case-studies')">
                            {{ $t('pages.caseStudies.see_more2') }} +
                        </glow-button>
                    </div>

                </div>

            </client-only>
            <VueEasyLightbox
                    :zoomDisabled="true"
                    :scrollDisabled="true"
                    :pinchDisabled="true"
                    :rotateDisabled="true"
                    :moveDisabled="true"
                    :visible="visibleRef"
                    :imgs="lightBoxImages"
                    :index="indexRef"
                    @hide="onHide"
            />

        </div>


    </div>

</template>

<script setup>
import {ref} from 'vue'
import glowButton from "../../blocks/glow-button/glow-button";

const page = ref(1)
const perPage = 24
const portfolios = ref([])
const isMore = ref(false)
const lightBoxImages = ref([])
const visibleRef = ref(false);
const indexRef = ref(0);
const isLoading = ref(false)

const props = defineProps({
    perPage: {
        type: Number,
        default: 24
    },
    link: {
        type: Boolean,
        default: false
    },
    noPadding: {
        type: Boolean,
        default: false
    },
    theme: {
        type: String,
        default: 'portfolio-dark'
    },
    showTitle: {
        type: Boolean,
        default: false
    }
})

const {data} = await useFetch(`https://rollin.wiki/api/portfolios?page=1&per_page=${props.perPage}`)
portfolios.value = [...portfolios.value, ...data.value.portfolios]
isMore.value = data.value.pagination.current_page !== data.value.pagination.last_page;
lightBoxImages.value = portfolios.value.map(portfolio => {
    return {
        src: 'https://rollin-wiki.s3.ca-central-1.amazonaws.com/' + portfolio.image_url,
        title: portfolio.title
    }
})

const loadMore = async () => {
    isLoading.value = true
    page.value++
    const data = await $fetch(`https://rollin.wiki/api/portfolios?page=${page.value}&per_page=${props.perPage}`)
    portfolios.value = [...portfolios.value, ...data.portfolios]
    isMore.value = data.pagination.current_page !== data.pagination.last_page;
    lightBoxImages.value = portfolios.value.map(portfolio => {
        return {
            src: 'https://rollin-wiki.s3.ca-central-1.amazonaws.com/' + portfolio.image_url,
            title: portfolio.title
        }
    })
    isLoading.value = false
}
const showImg = (index) => {
    indexRef.value = index;
    visibleRef.value = true;
};
const onHide = () => (visibleRef.value = false);


</script>